import React, { FC, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CookiesProvider } from 'react-cookie'

import './App.css'
import ChatContainer from './components/container/ChatContainer'
import { ChatWrapperProvider } from './provider/ChatWrapperProvider'
import store, { persistor } from './store/'
import Router from './router'
import { theme } from './theme'
import { ConfirmProvider } from 'material-ui-confirm'
import { ToastProvider } from './components/Toasts'
import { PersistGate } from 'redux-persist/integration/react'

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        clickable: true
    }
}

const App: FC = (): ReactElement => {
    const queryClient = new QueryClient()

    const defaultTheme = createTheme(theme)

    const appHeight = (): void => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()

    return (
        <BrowserRouter>
            <CookiesProvider defaultSetOptions={{ path: '/' }}>
                <PersistGate loading={null} persistor={persistor}>
                    <Provider store={store}>
                        <ThemeProvider theme={defaultTheme}>
                            <ToastProvider>
                                <QueryClientProvider client={queryClient}>
                                    <ConfirmProvider
                                        defaultOptions={{
                                            dialogProps: {
                                                maxWidth: 'xs',
                                            },
                                        }}
                                    >
                                        <ChatWrapperProvider>
                                            <Router />
                                        </ChatWrapperProvider>
                                    </ConfirmProvider>
                                </QueryClientProvider>
                            </ToastProvider>
                        </ThemeProvider>
                    </Provider>
                </PersistGate>
            </CookiesProvider>
        </BrowserRouter>
    )

    return (
        <QueryClientProvider client={queryClient}>
            <ChatWrapperProvider>
                <ChatContainer />
            </ChatWrapperProvider>
        </QueryClientProvider>
    )
}

export default App
