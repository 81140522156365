/* eslint-disable max-len */
import React, { FC } from 'react'
import {
    Button,
    FormLabel,
    Grid,
    Icon,
    Stack,
    Tooltip,
    // Typography,
} from '@mui/material'
import LanguagePicker from '../languagePicker/LanguagePicker'
import ScenarioPicker from '../scenarioPicker/ScenarioPicker'
import { useNavigate } from 'react-router-dom'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import NearMeDisabledIcon from '@mui/icons-material/NearMeDisabled'
import NearMeIcon from '@mui/icons-material/NearMe'
import { useCookies } from 'react-cookie'
import { COOKIES, SELECTED_BACKGROUND_COLOR } from '../../functions/constants'
import { ClearChats } from '../ClearChats'
import WithHeaderLayout from '../Layouts/WithHeaderLayout'
import { AccountIcon } from '../AccountIcon'
import { useChatMessage } from '../../provider/ChatWrapperProvider'
import { useToast } from '../Toasts'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

interface IToggleButtonProps {
    onClick: () => void
    text: string
    selected: boolean
    SelectedIcon: typeof Icon
    DisabledIcon: typeof Icon
}

const ToggleButton: FC<IToggleButtonProps> = ({
    onClick,
    selected,
    text,
    SelectedIcon,
    DisabledIcon,
}) => {
    return (
        <Tooltip title={text}>
            <Button
                variant="outlined"
                color="secondary"
                sx={{
                    maxWidth: '7vh',
                    minWidth: '7vh',
                    height: '7vh',
                    elevation: 10,
                    flex: 1,
                    padding: '10%',
                    margin: '5%',
                    flexDirection: 'column',
                    gap: '10px',
                    backgroundColor: selected
                        ? SELECTED_BACKGROUND_COLOR
                        : 'white',
                    borderColor: selected ? 'secondary' : 'white',
                    boxShadow: '0px 2px 8px #00000040',
                }}
                onClick={onClick}
            >
                {selected ? (
                    <SelectedIcon color="black" />
                ) : (
                    <DisabledIcon color="black" />
                )}
                {/* <Typography color={'black'}>{text}</Typography> */}
            </Button>
        </Tooltip>
    )
}

const ToggleButtonSection: FC = () => {
    const [cookies, setCookie] = useCookies([
        COOKIES.IS_MUTED,
        COOKIES.AUTO_SEND,
    ])

    const { showToast } = useToast()

    const setIsMuted = (value: boolean): void => {
        showToast(value ? 'Muted' : 'Unmuted', 'info')
        setCookie(COOKIES.IS_MUTED, value)
    }

    const setIsAutoSend = (value: boolean): void => {
        showToast(value ? 'Auto send enabled' : 'Auto send disabled', 'info')
        setCookie(COOKIES.AUTO_SEND, value)
    }

    return (
        <Grid container direction={'row'} justifyContent={'center'}>
            <Grid xs={3} display={'flex'} justifyContent={'center'}>
                <ToggleButton
                    selected={cookies[COOKIES.IS_MUTED]}
                    onClick={() => {
                        setIsMuted(!cookies[COOKIES.IS_MUTED])
                    }}
                    text="Mute"
                    SelectedIcon={VolumeOffIcon}
                    DisabledIcon={VolumeUpIcon}
                />
            </Grid>
            <Grid xs={3} display={'flex'} justifyContent={'center'}>
                <ToggleButton
                    selected={cookies[COOKIES.AUTO_SEND]}
                    onClick={() => {
                        setIsAutoSend(!cookies[COOKIES.AUTO_SEND])
                    }}
                    text={'Auto send'}
                    SelectedIcon={NearMeIcon}
                    DisabledIcon={NearMeDisabledIcon}
                />
            </Grid>
            <Grid xs={3} display={'flex'} justifyContent={'center'}>
                <ClearChats />
            </Grid>
        </Grid>
    )
}

const FormContent: FC = () => {
    const navigate = useNavigate()
    const { getSituation } = useChatMessage()
    const { showToast } = useToast()

    const onstartClick = (): void => {
        console.log({ getSituation })
        if (!getSituation) {
            showToast('Please select a scenario', 'error')
            return
        }
        navigate('/chat')
    }

    return (
        <WithHeaderLayout topRight={<AccountIcon />}>
            <Stack
                // ho
                // container
                spacing={4}
                sx={{
                    padding: '5%',
                    //  paddingTop: '5%'
                    // height: '80vh',
                    // overflow: 'scroll',
                    alignItems: 'flex-start',
                }}
                justifyContent={'left'}
                // direction={'column'}
            >
                {/* <Grid item xs={11} sm={11}> */}
                <Stack
                    direction={'row'}
                    width={'100%'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <FormLabel
                        component="legend"
                        style={{
                            fontFamily: 'monospace',
                            fontSize: '20px',
                        }}
                    >
                        Please pick the language
                    </FormLabel>
                    {/* </Grid>
                <Grid item xs={1} sm={1}> */}
                    {getSituation && (
                        <IconButton
                            onClick={() => {
                                onstartClick()
                            }}
                        >
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    )}
                </Stack>
                {/* </Grid>
                <Grid item xs={12} sm={12} style={{ paddingBottom: '5%' }}> */}
                <LanguagePicker />
                {/* </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    alignItems={'center'}
                    justifyContent="center"
                    direction={'column'}
                > */}
                <FormLabel
                    component="legend"
                    style={{
                        fontFamily: 'monospace',
                        fontSize: '20px',
                    }}
                >
                    Please pick the scenario
                </FormLabel>
                {/* </Grid>
                <Grid
                    display={'flex'}
                    flexDirection={'column'}
                    item
                    xs={12}
                    sm={12}
                    style={{ paddingBottom: '5%' }}
                    justifyContent={'center'}
                    alignItems={'center'}
                > */}
                <ScenarioPicker />
                {/* </Grid> */}
                <ToggleButtonSection />
                {/* <Grid
                    item
                    xs={12}
                    sx={{ alignItems: 'center', display: 'flex' }}
                > */}
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        // marginTop: '25px', // Adjust as needed
                        // width: '200px', // Adjust the width as needed
                        backgroundColor: '#4caf50', // Change the background color
                        color: '#fff', // Change the text color
                        '&:hover': {
                            backgroundColor: '#45a049', // Change the hover background color
                        },
                        margin: 'auto',
                        borderRadius: '10px',
                        padding: '10px',
                    }}
                    onClick={onstartClick}
                >
                    START
                </Button>
                {/* </Grid> */}
            </Stack>
        </WithHeaderLayout>
    )
}

const Initial: FC = () => {
    return <FormContent />
}

export default Initial
