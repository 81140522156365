// import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import React, { useRef, useState, useEffect } from 'react'
import { TEXT_TO_SPEECH_URL } from '../functions/constants'
import { useToast } from './Toasts'

const languageMap: { [key: string]: string } = {
    DE: 'de-DE',
    FR: 'fr-FR',
    GB: 'en-GB',
    ES: 'es-ES',
    PT: 'pt-PT',
    IT: 'it-IT',
}

export const textToSpeech = (text: string, lang = 'GB'): void => {
    const msg = new SpeechSynthesisUtterance(text)
    // msg.rate = 0.7
    // msg.pitch = 1
    msg.lang = languageMap[lang]
    window.speechSynthesis.speak(msg)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const AudioPlayer = ({ src }) => {
    return (
        <div>
            <audio controls>
                <source src={src} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </div>
    )
}

export const TextToSpeech = ({ text, lang = 'GB' }): React.ReactNode => {
    // const [speaking, setSpeaking] = React.useState(false)
    console.log(lang)

    const [isPlaying, setIsPlaying] = useState(false)
    const audioRef = useRef(new Audio())

    const handleAudioEnd = (): void => setIsPlaying(false)

    const { showToast } = useToast()

    let initial = true
    useEffect(() => {
        if (!initial) {
            return
        }
        initial = false
        const audio = audioRef.current
        if (audio) {
            audio.addEventListener('ended', handleAudioEnd)
        }
        return () => {
            if (audio) {
                audio.removeEventListener('ended', handleAudioEnd)
            }
        }
    }, [])

    const togglePlayPause = (): void => {
        const audio = audioRef.current
        if (isPlaying) {
            audio.pause()
            setIsPlaying(false)
        } else {
            audio.src = `${TEXT_TO_SPEECH_URL}?chat_response=${encodeURIComponent(
                text
            )}`
            setIsPlaying(true)
            audio.onended = () => setIsPlaying(false)
            audio
                .play()
                .catch(() => {
                    showToast('Error playing speech', 'error')
                    setIsPlaying(false)
                })
                .finally(() => {
                    console.log('playing')
                })
        }
    }

    if (isPlaying) {
        return <StopIcon onClick={togglePlayPause} />
    }
    return <VolumeUpIcon onClick={togglePlayPause} />

    return (
        <button onClick={togglePlayPause}>
            {isPlaying ? 'Pause' : 'Play'}
        </button>
    )
}
