import * as React from 'react'
// import Box from '@mui/material/Box'
// import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
// import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useChatMessage } from '../provider/ChatWrapperProvider'
import { useDataProvider } from '../functions/MainFunctions'
import he from 'he'

// const bull = (
//     <Box
//         component="span"
//         sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//     >
//         •
//     </Box>
// )

export default function TranslationCard({ word }): React.ReactElement {
    // const [meaningData, setMeaningData] = React.useState(null)
    const { getLang } = useChatMessage()
    const [translation, setTranslation] = React.useState<string | null>('')

    const dataProvider = useDataProvider()
    const to = 'en'

    React.useEffect(() => {
        // TranslatorConfiguration.setConfig(
        //   ProviderTypes.Google,
        //   GOOGLE_API_KEY,
        //   to,
        //   from
        // );

        // const translator = TranslatorFactory.createTranslator();
        // translator.translate(value).then((translated) => {
        //   //Do something with the translated text
        //   onTranslated(translated);
        // });
        // onTranslated('test')
        if (getLang?.translate_code === to) {
            setTranslation(word)
            return
        }
        if (word) {
            dataProvider
                .translateText({
                    text: word,
                    source_language: getLang.translate_code,
                })
                .then((res) => {
                    setTranslation(res?.data?.data)
                })

            // fetch(
            //     `https://translation.googleapis.com/language/translate/v2?key=${GOOGLE_TRANSLATE_API_KEY}&q=${word}&source=${getLang.translate_code}&target=${to}`
            // )
            //     .then((res) => res.json())
            //     .then((res) => {
            //         console.log({ res: res.data })
            //         // setTranslation('nfdjisafd')
            //         setTranslation(res?.data?.translations[0]?.translatedText)
            //     })
        }
    }, [getLang, word])

    // React.useEffect(() => {
    //     setMeaningData(null)
    // }, [word])

    // console.log({ word, meaningData })
    console.log({ translation })

    return (
        <>
            <CardContent>
                <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                >
                     {`English Translation`}
                </Typography>
                <Typography variant="h5" component="div">
                    {/* {word} */}
                    {`${getLang.label} : ${word ? word : ""}`}
                </Typography>
                <Typography variant="h5" component="div">
                    {/* {he.decode(translation)} */}
                    {`${"English"} : ${translation ? he.decode(translation) : ""}`}
                </Typography>
                {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    adjective
                </Typography>
                <Typography variant="body2">
                    well meaning and kindly.
                    <br />
                    {'"a benevolent smile"'}
                </Typography> */}
            </CardContent>
            {/* <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions> */}
        </>
    )
}
