/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from 'axios'
import { BACKEND_URL, LOGIN_URL, RESET_CHAT_URL, config } from './constants'
import { useAxios } from './axiosHook'
// import { useCookies } from 'react-cookie'

axios.defaults.withCredentials = true

export const fullLanguagesList = async () => {
    return await axios.get(`${config.url.GET_LANGS}`, {})
}

export const getResponse = async (
    situationId,
    language,
    threadId,
    msgDecoded
) => {
    return await axios.post(`${config.url.GET_RESPONSE}`, {
        situation_id: situationId,
        language: language,
        thread_id: threadId,
        msg_decoded: msgDecoded,
    })
}

export const useDataProvider = () => {
    const { publicAxios, authAxios } = useAxios()
    // const [cookies] = useCookies([COOKIES.TOKEN])

    return {
        login: (data) => {
            return publicAxios.post(LOGIN_URL, data)
        },
        getResponse: (situationId, language, threadId, msgDecoded) => {
            return authAxios.post(`${config.url.GET_RESPONSE}`, {
                situation_id: situationId,
                language: language,
                thread_id: threadId,
                msg_decoded: msgDecoded,
            })
        },
        fullLanguagesList: () => {
            return authAxios.get(`${config.url.GET_LANGS}`)
        },
        speechToText: (formData) => {
            return authAxios.post(`${BACKEND_URL}/voice_to_text/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        },
        resetChat: (threadId) => {
            return authAxios.post(RESET_CHAT_URL, {
                thread_id: threadId,
            })
        },
        errorCorrect: (data) => {
            return authAxios.post(`${BACKEND_URL}/error_correct/`, data)
        },
        translateText: (data) => {
            return authAxios.post(`${BACKEND_URL}/translate_text/`, data)
        },
    }
}
