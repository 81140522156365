import React, { FC } from 'react'
// import styled from '@emotion/styled'
import { Grid, IconButton } from '@mui/material'
import { SettingsOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import {
    COOKIES,
    // INITIAL_HEADER_BACKGROUND
} from '../../functions/constants'
import VolumeOff from '@mui/icons-material/VolumeOff'
import VolumeUp from '@mui/icons-material/VolumeUp'
import { useCookies } from 'react-cookie'

// const HeaderContainer = styled(Paper)(() => ({
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backdropFilter: 'blur(10px)',
//     padding: '12px',
//     width: '100%', // Adjusted width for responsiveness
//     position: 'relative',
//     overflow: 'hidden',
//     boxSizing: 'border-box',
//     background: INITIAL_HEADER_BACKGROUND,
//     opacity: 0.9,
//     // '@media (min-width: 768px)': {
//     //     width: '30%', // Adjust as needed for larger screens
//     // },
//     // borderRadius: '0 0 32px 32px',
// }))

const ChatScreenActions: FC = () => {
    const [cookies, setCookie] = useCookies([
        COOKIES.IS_MUTED,
        COOKIES.AUTO_SEND,
    ])

    const toggleIsMuted = (): void => {
        setCookie(COOKIES.IS_MUTED, !cookies[COOKIES.IS_MUTED])
    }

    return (
        <Grid
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
            // sx={{ backgroundColor: 'red' }}
            direction={'row'}
            columnGap={'2%'}
        >
            <Grid>
                <IconButton onClick={toggleIsMuted} sx={{ color: 'grey' }}>
                    {cookies[COOKIES.IS_MUTED] ? (
                        <VolumeOff fontSize="large" />
                    ) : (
                        <VolumeUp fontSize="large" />
                    )}
                </IconButton>
            </Grid>
            <Grid>
                <Link to="/">
                    <SettingsOutlined fontSize="large" sx={{ color: 'grey' }} />
                </Link>
            </Grid>
        </Grid>
    )
}

export default ChatScreenActions
