/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const getAuthToken = (state) => state.general.token
export const getIsAuthenticated = (state) => state.general.token != null

export const _getLang = (state) => state.chat.getLang
export const _getUID = (state) => state.chat.getUID
export const _getThreadID = (state) => state.chat.getThreadID
export const _getChats = (state) => state.chat.getChats
export const _getSituation = (state) => state.chat.getSituation

export const getErrorCorrections = (state) => state.general.errorCorrectStore
