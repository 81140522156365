import React from 'react'
import { useConfirm } from 'material-ui-confirm'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { useChatMessage } from '../provider/ChatWrapperProvider'
import DeleteIcon from '@mui/icons-material/Delete'

export const ClearChats: React.FC = () => {
    const confirm = useConfirm()
    const { resetChats } = useChatMessage()

    const handleClick = (): void => {
        confirm({ title: 'Are you sure you want to clear all chats?' })
            .then(() => {
                resetChats()
            })
            .catch(() => {})
    }

    return (
        <Tooltip title={'delete chats'}>
            <Button
                variant="outlined"
                color="secondary"
                sx={{
                    maxWidth: '7vh',
                    minWidth: '7vh',
                    height: '7vh',
                    elevation: 10,
                    flex: 1,
                    padding: '10%',
                    margin: '5%',
                    flexDirection: 'column',
                    gap: '10px',
                    backgroundColor: 'white',
                    borderColor: 'secondary',
                    boxShadow: '0px 2px 8px #00000040',
                }}
                onClick={handleClick}
            >
                <DeleteIcon />
            </Button>
        </Tooltip>
    )
}
