import React from 'react'
import Container from '@mui/material/Container'
import { Outlet } from 'react-router-dom'
import {
    LIGHT_BACKGROUND,
    // LIGHT_BACKGROUND,
    WINDOW_BACKGROUND,
} from '../../functions/constants'

const MobileLayout = (): React.ReactElement => {
    return (
        <div
            style={{
                height: 'var(--app-height)',
                width: '100vw',
                backgroundColor: WINDOW_BACKGROUND,
                overflow: "hidden",
                
            }}
        >
            <Container
                maxWidth="sm"
                style={{ paddingLeft: 0, paddingRight: 0, overflowY: "auto" }}
                sx={{
                    maxHeight: "var(--app-height)",
                    minHeight: 'var(--app-height)',
                    backgroundColor: LIGHT_BACKGROUND,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // borderRadius: '10px',
                    // paddingLeft: 0,
                    overflow: 'scroll',
                    overflowX: 'hidden',
                    // flex: 1,
                }}
            >
                <Outlet />
            </Container>
        </div>
    )
}

export default MobileLayout
