/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from 'axios'
import { BACKEND_URL, COOKIES } from './constants'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../store/selectors'
import { useNavigate } from 'react-router-dom'

export const useAxios = () => {
    const [cookies, setCookies] = useCookies([COOKIES.TOKEN])
    const token = useSelector(getAuthToken)
    const navigate = useNavigate()

    const publicAxios = axios.create({
        baseURL: BACKEND_URL,
    })

    const authAxios = axios.create({
        baseURL: BACKEND_URL,
    })

    authAxios.interceptors.request.use(
        (config) => {
            console.log(
                'AUTH AXIOS REQUEST',
                config.headers.Authorization,
                cookies[COOKIES.TOKEN]
            )
            if (!config.headers.Authorization) {
                config.headers.Authorization = `Bearer ${token}`
            }

            return config
        },
        (error) => {
            console.error('AUTH AXIOS ERROR', error)
            return Promise.reject(error)
        }
    )

    authAxios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            if (error.response?.status === 401) {
                //place your reentry code
                setCookies(COOKIES.TOKEN, '', { path: '/' })
                navigate('/')
                localStorage.clear()
            }
            return error
        }
    )

    return { publicAxios, authAxios }
}
