import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import React from 'react'
// import { useCookies } from 'react-cookie'
// import { COOKIES } from '../../functions/constants'
import { useToast } from '../Toasts'
import { useDataProvider } from '../../functions/MainFunctions'
import { useDispatch } from 'react-redux'
import { setToken } from '../../store/reducers/generalReducer'
import { COOKIES } from '../../functions/constants'
import { useCookies } from 'react-cookie'
import { _setUID } from '../../store/reducers/chatReducer'
// import { useNavigate } from 'react-router-dom'

const LoginForm = (): React.ReactElement => {
    const setCookies = useCookies([COOKIES.TOKEN])[1]
    const { showToast } = useToast()
    const dispatch = useDispatch()
    const dataProvider = useDataProvider()
    // const navigate = useNavigate()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        data.set(
            'username',
            data.get('username')?.toString().toLowerCase() || ''
        )

        dataProvider
            .login(data)
            .then((response) => {
                console.log(response)
                showToast('Login successful', 'success')
                setCookies(COOKIES.TOKEN, response.data.access_token)
                console.log(response.data.access_token)
                dispatch(setToken(response.data.access_token))
                dispatch(_setUID(response.data.uid))
            })
            .catch((error) => {
                console.log(error)
                showToast('Login failed', 'error')
            })
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
                mt: 1,
                // backgroundColor: 'red'
            }}
        >
            <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email Address"
                name="username"
                autoComplete="username"
                autoFocus
                sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"Í
            /> */}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, borderRadius: '10px' }}
            >
                Log in
            </Button>
        </Box>
    )
}

export default LoginForm
