/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDataProvider } from '../functions/MainFunctions'
// import { Backdrop, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { _getLang, _getThreadID, _getUID, _getChats, _getSituation } from '../store/selectors'
import { _setLang, _setThreadID, _setChats, _setSituation, _setUID } from '../store/reducers/chatReducer'

// Create a context
const ChatMessageContext = createContext()

// const localStorageGetter = (key, defaultValue) => {
//     return () => {
//         const data = localStorage.getItem(key)
//         return data ? JSON.parse(data) : defaultValue
//     }
// }

// const localStorageSetter = (key, value) => {
//     localStorage.setItem(key, JSON.stringify(value))
// }

// Create a provider
export const ChatWrapperProvider = ({ children }) => {
    // const [getLang, setLang] = useState()
    // const [getUID, setUID] = useState()
    // const [getThreadID, setThreadID] = useState('')
    // const [getChats, setChats] = useState([])
    // const [getSituation, setSituation] = useState()
    const getLang = useSelector(_getLang)
    const getUID = useSelector(_getUID)
    const getThreadID = useSelector(_getThreadID)
    const getSituation = useSelector(_getSituation)
    const getChats = useSelector(_getChats)

    const dispatch = useDispatch()
    const setLang = (lang) => dispatch(_setLang(lang))
    const setUID = (uid) => dispatch(_setUID(uid))
    const setThreadID = (threadID) => dispatch(_setThreadID(threadID))
    const setChats = (chats) => dispatch(_setChats(chats))
    const setSituation = (situation) => dispatch(_setSituation(situation))

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const dataProvider = useDataProvider()

    const mapSituation = { 'Night out': 1, 'Shopping Day': 2 }

    // useEffect(() => {
    //     localStorageSetter('getLang', getLang)
    //     localStorageSetter('getSituation', getSituation)
    //     localStorageSetter('getUID', getUID)
    //     localStorageSetter('getThreadID', getThreadID)
    //     localStorageSetter('getChats', getChats)

    // }, [getLang, getSituation, getUID, getThreadID, getChats])

    useEffect(() => {
        if (getUID) {
            setThreadID(
                getUID +
                    '_' +
                    mapSituation[getSituation?.id] +
                    '_' +
                    getLang?.label
            )
            console.log({getChats})
        }
    }, [getLang, getSituation, getUID])

    useEffect(() => {
        const selectedLang = JSON.parse(window.getCookie('selected_language'))
        const selectedSituation = JSON.parse(
            window.getCookie('selected_situation')
        )
        if (selectedSituation) {
            setSituation(selectedSituation)
        }
        if (!getLang && selectedLang == null) {
            navigate('/')
        } else if (selectedLang) {
            setLang(selectedLang)
        }
    }, [])

    // useEffect(() => {
    //     let uid = window.getCookie('uid')
    //     if (uid == null) {
    //         uid = window.makeid(10)
    //         window.setCookie('uid', uid, 365)
    //         setUID(uid)
    //         setChats([])
    //         // alert(uid)
    //     } else {
    //         setUID(uid)
    //     }
    // }, [])

    const initialApiCall = (): void => {
        setLoading(true)
        dataProvider
            .getResponse(
                mapSituation[getSituation?.id],
                getLang?.label,
                getThreadID,
                null
            ) // first call to api
            .then((res) => {
                if (res.data.success) {
                    window.saveArrayToLocalStorage('chat_' + getThreadID, [
                        { content: res.data.data, agentFlag: true },
                    ])
                    setChats([{id: window.makeid(15), content: res.data.data, agentFlag: true }])
                } else {
                    console.log('Error in the response API.')
                }
            })
            .catch((err) => {
                console.log('Error (' + err + ') in generating response.')
            }).finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 2000)
                // setLoading(false)
            })
    }

    const resetChats = (thread_id) => {
        setLoading(true)
        if (thread_id == null) {
            const allKeys = Object.keys(localStorage)
            allKeys.map((key) => {
                if (key.includes('chat_')) {
                    localStorage.setItem(key, [])
                }
            })
        } else {
            window.saveArrayToLocalStorage('chat_' + thread_id, [])
            dataProvider.resetChat(thread_id)
        }
        setChats([])
        initialApiCall()
    }

    const contextValue = {
        setChats,
        setLang,
        setSituation,
        setThreadID,
        getChats,
        getLang,
        getThreadID,
        getUID,
        setUID,
        getSituation,
        resetChats,
        initialApiCall,
        loading,
        setLoading,
    }

    return (
        <ChatMessageContext.Provider value={contextValue}>
            {children}
        </ChatMessageContext.Provider>
    )
}

// Custom hook to access the context
export const useChatMessage = () => {
    const context = useContext(ChatMessageContext)
    if (!context) {
        throw new Error(
            'useChatMessage must be used within a ChatMessageProvider'
        )
    }
    return context
}
