import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Grid, Paper, Typography } from '@mui/material'
import { useChatMessage } from '../../provider/ChatWrapperProvider'
import RightClickableText from '../RightClickableText'

const TopContainer = styled(Paper)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(10px)',
    padding: '12px',
    width: '90%', // Adjusted width for responsiveness
    // position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    background: 'white',
    opacity: 0.7,
    // '@media (min-width: 768px)': {
    //     width: '30%', // Adjust as needed for larger screens
    // },
    borderRadius: '8px',
    // boxShadow: '0 3px 6px rgba(0, 0, 0, 0.5)'
}))

const TitleContainer: FC = () => {
    const { getSituation, getLang } = useChatMessage()

    return (
        <TopContainer>
            <Grid
                container
                spacing={2}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                direction={'row'}
            >
                <Grid item>
                    <Typography variant="h5" fontWeight={'bold'}>
                        Objective
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={11}>
                    {/* <Typography
                        variant="h6"
                        component="h6"
                        sx={{
                            paddingBottom: '0px',
                            visibility: 'visible',
                            textAlign: 'center',
                        }}
                    > */}
                    <RightClickableText
                        variant="h6"
                        typographyProps={{
                            component: 'h6',
                            sx: {
                                paddingBottom: '0px',
                                visibility: 'visible',
                                textAlign: 'center',
                            },
                        }}
                    >
                        {getSituation?.topic[getLang?.code || 'GB'] +
                            ': ' +
                            getSituation?.value[getLang?.code || 'GB']}
                    </RightClickableText>
                    {/* </Typography> */}
                </Grid>
            </Grid>
        </TopContainer>
    )
}

export default TitleContainer
