import React, { FC, useEffect } from 'react'
// import Box from '@mui/material/Box'
// import TextField from '@mui/material/TextField'
// import Autocomplete from '@mui/material/Autocomplete'
import { useChatMessage } from '../../provider/ChatWrapperProvider'
import SituationCard from './SituationCard'

const scenarios: readonly ScenarioType[] = [
    {
        img: 'https://images.unsplash.com/photo-1517604931442-7e0c8ed2963c?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        value: {
            GB: 'Your friend wants to watch a film. interested?',
            DE: 'Dein Freund möchte einen Film sehen. Interessiert?',
            FR: 'Votre ami veut regarder un film. Intéressé?',
            ES: 'Tu amigo quiere ver una película. ¿Interesado?',
            PT: 'Seu amigo quer assistir a um filme. Interessado?',
            IT: 'Il tuo amico vuole guardare un film. Interessato?',
        },
        id: 'Night out',
        topic: {
            GB: 'Night out',
            DE: 'Nacht draußen',
            FR: 'Soirée',
            ES: 'Noche fuera',
            PT: 'Noite fora',
            IT: 'Uscita serale',
        },
    },
    {
        id: 'Shopping Day',
        img: 'https://images.unsplash.com/photo-1534452203293-494d7ddbf7e0?q=80&w=3544&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        value: {
            GB: 'You want to buy some cloths. Imagine your discussion with the shopkeeper.',
            // eslint-disable-next-line max-len
            DE: 'Du möchtest etwas Kleidung kaufen. Stellen Sie sich Ihre Diskussion mit dem Ladenbesitzer vor.',
            FR: 'Vous voulez acheter des vêtements. Imaginez votre discussion avec le commerçant.',
            ES: 'Quieres comprar ropa. Imagina tu discusión con el tendero.',
            PT: 'Você quer comprar algumas roupas. Imagine sua discussão com o lojista.',
            IT: 'Vuoi comprare dei vestiti. Immagina la tua discussione con il negoziante.',
        },
        topic: {
            GB: 'Shopping Day',
            DE: 'Einkaufstag',
            FR: 'Journée shopping',
            ES: 'Día de compras',
            PT: 'Dia de compras',
            IT: 'Giornata di shopping',
        },
    },
]

// const scenarios: readonly ScenarioTypeStr[] = [
//     {
//         img: 'https://images.unsplash.com/photo-1517604931442-7e0c8ed2963c?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//         value: 'Your friend wants to watch a film. interested?',
//         id: 'Night out',
//     },
//     {
//         img: 'https://images.unsplash.com/photo-1534452203293-494d7ddbf7e0?q=80&w=3544&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//         value: 'You want to buy some cloths. Imagine your discussion with the shopkeeper.',
//         id: 'Shopping Day',
//     },
// ]

const ScenarioPicker: FC = () => {
    const { getSituation, setSituation, getLang } = useChatMessage()
    useEffect(() => {
        if (!getSituation) {
            setSituation(scenarios[0]) // if no default, set first
        }
    }, [])
    return (
        <>
            {scenarios?.map((situation: ScenarioType, index: number) => (
                <SituationCard
                    onClick={() => setSituation(situation)}
                    selected={getSituation?.id === situation.id}
                    key={index}
                    title={situation.topic[getLang?.code || 'GB']}
                    description={situation.value[getLang?.code || 'GB']}
                    image={situation.img}
                />
            ))}
        </>
    )
}

export default ScenarioPicker

interface TranslatedString {
    //Add all languages
    DE: string
    FR: string
    GB: string
    ES: string
    PT: string
    IT: string
}

interface ScenarioType {
    id: string
    img: string
    topic: TranslatedString
    value: TranslatedString
}

// interface ScenarioTypeStr {
//     img: string
//     id: string
//     value: string
// }
