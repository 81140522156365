import * as React from 'react'
import Menu from '@mui/material/Menu'
import { Card, Typography } from '@mui/material'
import MeaningCard from './MeaningCard'
// import { useChatMessage } from '../provider/ChatWrapperProvider'

export default function RightClickableText({
    agentFlag = false,
    variant = 'body1',
    children,
    typographyProps = { variant: 'body1' },
}): React.ReactElement {
    const [contextMenu, setContextMenu] = React.useState<{
        word: string
        mouseX: number
        mouseY: number
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent, word: string): void => {
        event.preventDefault()
        setContextMenu(
            contextMenu === null
                ? {
                      word,
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        )
    }

    const handleClose = (): void => {
        setContextMenu(null)
    }

    // const onTranslate = () => {
    //     fetch(
    //         `https://translation.googleapis.com/language/translate/v2?key=${GOOGLE_TRANSLATE_API_KEY}&q=${value}&source=${from}&target=${to}`
    //     )
    //         .then((res) => res.json())
    //         .then((res) => {})
    // }

    console.log({ children })

    return (
        <div
            // onContextMenu={handleContextMenu}
            style={{ cursor: agentFlag ? 'context-menu' : 'auto' }}
        >
            <Typography variant={variant} {...typographyProps}>
                {children &&
                    children?.split(' ').map((word, index) => {
                        return (
                            <span
                                // variant={variant}
                                onContextMenu={(e: React.MouseEvent) => {
                                    // if (agentFlag) {
                                    handleContextMenu(e, word)
                                    // }
                                }}
                                key={index}
                            >
                                {`${word} `}
                            </span>
                        )
                    })}
            </Typography>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                sx={{ padding: 0, backgroundColor: 'transparent' }}
                component={Card}
            >
                <MeaningCard word={contextMenu?.word} />
            </Menu>
        </div>
    )
}
