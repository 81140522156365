/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */

import React, { FC, useEffect, useRef, useState } from 'react'
import {
    Box,
    Paper,
    TextField,
    styled,
    IconButton,
    Avatar,
    Grid,
    Badge,
    Tooltip,
    Typography,
    Backdrop,
    CircularProgress,
    ClickAwayListener,
    Button,
} from '@mui/material'
import { Height, Mic, Person, SendOutlined } from '@mui/icons-material'
import { keyframes } from '@emotion/react'

// api response from the backend
import { getResponse, useDataProvider } from '../../functions/MainFunctions'
import TitleContainer from '../titleContainer/TitleContainer'
import ChatScreenActions from '../chatScreenActions'
import { useChatMessage } from '../../provider/ChatWrapperProvider'
import { useNavigate } from 'react-router-dom'
import RightClickableText from '../RightClickableText'
import SpeechToText from '../SpeechToText'
// import { useAudioRecorder } from 'react-audio-voice-recorder'
import { useToast } from '../Toasts'
import {
    COOKIES,
    TEXT_TO_SPEECH_URL,
    TYPING_CONTAINER_BACKGROUND,
} from '../../functions/constants'
import { TextToSpeech, textToSpeech } from '../TextToSpeech'
import { useCookies } from 'react-cookie'
import ReplayIcon from '@mui/icons-material/Replay'
import { useConfirm } from 'material-ui-confirm'
import WithHeaderLayout from '../Layouts/WithHeaderLayout'
import { AlwaysScrollToBottom } from '../AlwaysScrollToBottom'
import { useDispatch, useSelector } from 'react-redux'
import { getErrorCorrections } from '../../store/selectors'
import { setErrorCorrect } from '../../store/reducers/generalReducer'

const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

const GlassContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // minHeight: '70vh',
    backdropFilter: 'blur(10px)',
    borderRadius: '0 0 0 0',
    // padding: '12px',
    boxShadow: theme.shadows[0],
    width: '100%', // Adjusted width for responsiveness
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    background: 'floralwhite',
    opacity: 0.9,
    // '@media (min-width: 768px)': {
    //     width: '30%', // Adjust as needed for larger screens
    // },
}))

type ChatMessageProps = {
    id: string
    content: string
    agentFlag: boolean
}

const AutoCorrectWrapper = ({
    children,
    chat,
}: {
    children: React.ReactNode
    chat: ChatMessageProps
}): React.ReactNode => {
    const [isCorrect, setCorrect] = useState<boolean | null>(null)
    const [data, setData] = useState<string | null>()
    const { getLang, getThreadID } = useChatMessage()
    const dataProvider = useDataProvider()
    const [open, setOpen] = useState(false)

    const errorCorrections = useSelector(getErrorCorrections)
    console.log({ errorCorrections })

    const dispatch = useDispatch()

    const handleTooltipClose = (): void => {
        setOpen(false)
    }

    const addErrorCorrect = (
        id: string,
        error: { data: string; success: boolean; correct: boolean }
    ): void => {
        // const errorCorrects = { ...errorCorrections }
        // errorCorrects[id] = error
        // console.log('addErrorCorrect', errorCorrects, id, error)
        dispatch(setErrorCorrect({ id, data: error }))
    }

    const handleTooltipOpen = (): void => {
        setOpen(true)
    }

    const initial = useRef(true)
    useEffect(() => {
        if (initial.current) {
            initial.current = false
            return
        }

        console.log('Autocorrect called', chat, getLang.label)
        if (chat.agentFlag) {
            return
        }
        if (errorCorrections.hasOwnProperty(chat.id)) {
            const res = errorCorrections[chat.id]
            setCorrect(res.correct)
            setData(res.data)
        } else {
            dataProvider
                .errorCorrect({
                    language: getLang.label,
                    msg_decoded: chat.content,
                    thread_id: getThreadID,
                })
                .then((res) => {
                    console.log(res.data)
                    if (res.data.success) {
                        addErrorCorrect(chat.id, res.data)
                        setCorrect(res.data.correct)
                        setData(res.data.data)
                    }
                })
        }
    }, [dataProvider, getLang.label, chat, getThreadID, errorCorrections])

    const correctMark = '✔'
    // const wrongMark = '✘'

    if (chat.agentFlag || isCorrect === null) {
        return children
    }

    return (
        <Box>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    // disableFocusListener
                    // disableHoverListener
                    // disableTouchListener
                    title={data && <Typography>{data}</Typography>}
                    placement="top-start"
                    style={{ fontSize: '20px' }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    {/* <Button onClick={handleTooltipOpen}> */}
                    <Badge
                        onClick={handleTooltipOpen}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        color={isCorrect ? 'success' : 'warning'}
                        badgeContent={isCorrect ? correctMark : 'correction'}
                        // variant="dot"
                    >
                        {children}
                    </Badge>
                    {/* </Button> */}
                </Tooltip>
            </ClickAwayListener>
        </Box>
    )
}

export const ChatMessage: FC<ChatMessageProps> = ({
    id,
    content,
    agentFlag,
}) => {
    const { getLang } = useChatMessage()
    const avatarColor = agentFlag ? 'darkseagreen' : 'gainsboro'
    const gradientColor = agentFlag ? 'darkseagreen' : 'gainsboro'

    const shouldWrap = content.length > 20

    return (
        <Box
            mt={2}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: agentFlag ? 'flex-start' : 'flex-end',
                width: '100%', // Occupy the full width
                marginLeft: 0,
                marginRight: 0,
                marginBottom: '10px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: agentFlag ? 'flex-start' : 'flex-end',
                    width: '100%', // Occupy the full width
                }}
            >
                {agentFlag && (
                    <Avatar
                        sx={{
                            width: '20px',
                            height: '20px',
                            color: 'rgba(255, 255, 255, 0.7)',
                            backgroundColor: avatarColor,
                            margin: '0 5px',
                            padding: '1%',
                        }}
                    >
                        <Person fontSize="small" />
                    </Avatar>
                )}

                <AutoCorrectWrapper chat={{ id, content, agentFlag }}>
                    <Box
                        sx={{
                            borderRadius: agentFlag
                                ? '10px 10px 10px 0'
                                : '10px 10px 0 10px',
                            padding: '10px',
                            background: gradientColor,
                            // maxWidth: shouldWrap ? '66%' : 'fit-content',
                            wordWrap: 'break-word',
                            whiteSpace: shouldWrap ? 'normal' : 'nowrap',
                            overflow: shouldWrap ? 'visible' : 'hidden',
                            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.5)',
                            height: 'auto', // Adjusted height to 'auto'
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <RightClickableText
                                variant="body1"
                                agentFlag={agentFlag}
                            >
                                {content}
                            </RightClickableText>
                        </div>
                        {/* <Typography variant="body1">{content}</Typography> */}
                    </Box>
                </AutoCorrectWrapper>
                <Box sx={{ height: '100%', padding: '1%' }}>
                    {agentFlag && (
                        <TextToSpeech text={content} lang={getLang.code} />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export const TypingContainer: any = ({
    getLang,
    getSituation,
    getUID,
    getThreadID,
    getChats,
    setChats,
}) => {
    const [newMsg, setMsg] = useState('')
    const [cookies] = useCookies([COOKIES.IS_MUTED])
    const navigate = useNavigate()
    const mapSituation = { 'Night out': 1, 'Shopping Day': 2 }
    const { showToast } = useToast()
    const dataProvider = useDataProvider()
    const { initialApiCall, loading, setLoading } = useChatMessage()

    const handleVoiceInput = (): void => {
        alert(
            'Voice input is not implemented. You can integrate a voice input library.'
        )
    }

    interface ChatMessage {
        id: string
        content: string
        agentFlag: boolean
    }

    const addChats = (chats: ChatMessage[]): void => {
        const new_chats = getChats.concat(chats)
        window.saveArrayToLocalStorage('chat_' + getThreadID, new_chats)
        setChats(new_chats)
    }

    useEffect(() => {
        setChats([])
        const chatData = window.getArrayFromLocalStorage('chat_' + getThreadID)
        console.log('chatData', chatData)
        if (chatData == null || chatData.length == 0) {
            if (!getUID || !getLang || !getSituation) {
                console.log('waiting for the details to initiate the chat.')
            } else {
                initialApiCall()
            }
        } else {
            setChats(chatData)
        }
    }, [getThreadID, getUID])

    const handleSendMessage = (message?: string): void => {
        setLoading(true)
        const msg = message ? message : newMsg
        const msgId = window.makeid(15)
        if (!getUID || !getLang || !getSituation) {
            alert(
                'Unexpected error. Please select the Language and the Situation again.'
            )
            navigate('/')
        } else {
            addChats([{ id: msgId, content: msg, agentFlag: false }])
            setMsg('')
            dataProvider
                .getResponse(
                    mapSituation[getSituation?.id],
                    getLang?.label,
                    getUID +
                        '_' +
                        mapSituation[getSituation?.id] +
                        '_' +
                        getLang?.label,
                    msg
                )
                .then((res) => {
                    if (res.data.success) {
                        if (!cookies[COOKIES.IS_MUTED]) {
                            // textToSpeech(res.data.data, getLang.code)
                            const audio = new Audio(
                                `${TEXT_TO_SPEECH_URL}?chat_response=${encodeURIComponent(
                                    res.data.data
                                )}`
                            )
                            audio.play().catch(() => {
                                showToast('Error playing speech', 'error')
                            })
                        }
                        addChats([
                            {
                                id: msgId,
                                content: msg,
                                agentFlag: false,
                            },
                            {
                                id: window.makeid(15),
                                content: res.data.data,
                                agentFlag: true,
                            },
                        ])
                    } else {
                        console.log('Error in the response API.')
                    }
                })
                .catch((err) => {
                    console.log('Error (' + err + ') in generating response.')
                })
        }
        setTimeout(() => {
            setLoading(false)
        }, 200)
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const onChangeMsg = (event) => {
        setMsg(event.target.value)
    }

    // speechRecognition = new window.speechRe()
    // speechRecognition.onresult = console.log
    // speechRecognition.start()

    return (
        <Box
            sx={{
                // position: 'absolute',
                // bottom: '0',
                // left: '0',
                // right: '0',
                // height: '8vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: TYPING_CONTAINER_BACKGROUND,
                boxSizing: 'border-box',
                zIndex: 1,
                padding: '10px 10px 10px 10px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                gap: '10px',
                width: '100%',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
            }}
        >
            <TextField
                label="Type a message..."
                fullWidth
                onChange={onChangeMsg}
                value={newMsg}
                variant="outlined"
                lang="it"
                // style={{
                //     color: 'rgba(0, 0, 0, 0.6)',
                // }}
                // sx={{
                //     '& .Mui-focused': {
                //         color: '#fed136 !important', // Text color when focused
                //     },
                //     '& .Mui-focused fieldset': {
                //         borderColor: '#fed136 !important', // Border color when focused
                //     },
                // }}
                onKeyDownCapture={(data: any) => {
                    console.log('key pressed', data)
                    if (data.code == 'Enter' && newMsg != '') {
                        console.log('Key `Enter` pressed')
                        handleSendMessage()
                    }
                }}
            />

            {/* <IconButton
                style={{ color: 'rgba(0, 0, 0, 0.6)' }}
                onClick={handleVoiceInput}
            >
                <Mic />
            </IconButton> */}
            {newMsg == '' ? (
                <SpeechToText
                    // recorderControls={recorderControls}
                    setMessage={setMsg}
                    sendMessage={handleSendMessage}
                />
            ) : (
                <IconButton
                    style={{ color: 'rgba(0, 0, 0, 0.6)' }}
                    onClick={() => handleSendMessage()}
                >
                    <SendOutlined />
                </IconButton>
            )}
        </Box>
    )
}

const ChatContainer: FC = () => {
    const {
        getLang,
        getChats,
        getSituation,
        getThreadID,
        setChats,
        getUID,
        resetChats,
        loading,
    } = useChatMessage()

    const confirm = useConfirm()

    const handleClick = (): void => {
        confirm({ title: 'Are you sure you want to clear conversation?' })
            .then(() => {
                console.log('clear chats') //TODO implement clear chats
                resetChats(getThreadID)
            })
            .catch(() => {})
    }
    return (
        // <Box
        //     sx={{
        //         borderTopLeftRadius: '30px',
        //         display: 'flex',
        //         flexDirection: 'column',
        //         alignItems: 'center', // Center align the content
        //         justifyContent: 'center', // Center align vertically
        //         minHeight: '100vh',
        //         background: '#0f1626',
        //         backgroundColor: 'transparent',
        //         backgroundSize: 'cover',
        //         fontWeight: 600,
        //         width: '100%',
        //         WebkitJustifyContent: 'space-between',
        //     }}
        // >
        //     <div
        //         style={{
        //             display: 'flex',
        //             width: '100%',
        //             justifyContent: 'center',
        //             flexDirection: 'column',
        //         }}
        //     >
        //         <ChatScreenActions />
        //         <Grid
        //             item
        //             display={'flex'}
        //             alignItems={'center'}
        //             justifyContent={'right'}
        //         >
        //             <IconButton onClick={handleClick} sx={{ padding: '5px' }}>
        //                 <ReplayIcon fontSize="large" />
        //             </IconButton>
        //         </Grid>
        //     </div>

        //     <GlassContainer elevation={3}>
        <WithHeaderLayout topRight={<ChatScreenActions />}>
            <Box
                boxShadow={0}
                sx={{
                    // position: 'absolute',
                    // top: '0',
                    // left: '0',
                    // right: '0',
                    // bottom: '80px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    // padding: '12px',
                    overflow: 'auto',
                    // boxSizing: 'border-box',
                    zIndex: 1,
                    scrollbarWidth: 'thin',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '4px',
                    },
                    height: '72vh',
                }}
            >
                <Box sx={{ padding: '4%' }}>
                    <Grid
                        item
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        padding={'10px'}
                    >
                        <TitleContainer />
                    </Grid>
                    {Object.values(getChats).map((x, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <ChatMessage
                            key={x['id']}
                            id={x['id']}
                            content={x['content']}
                            agentFlag={x['agentFlag']}
                        />
                    ))}
                    <AlwaysScrollToBottom />
                </Box>
            </Box>
            <Box
                sx={{
                    height: '7vh',
                    // paddingRight: '10%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    backgroundColor: 'transparent',
                    width: '100%',
                    position: 'absolute',
                    zIndex: 10000,
                }}
            >
                <IconButton onClick={handleClick} sx={{ padding: '5px' }}>
                    <ReplayIcon fontSize="large" />
                </IconButton>
            </Box>
            <TypingContainer
                getLang={getLang}
                getSituation={getSituation}
                getUID={getUID}
                getThreadID={getThreadID}
                getChats={getChats}
                setChats={setChats}
            />
            <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </WithHeaderLayout>
    )
}

export default ChatContainer
