import React, { FC, useEffect } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useChatMessage } from '../../provider/ChatWrapperProvider'

export const languages: readonly CountryType[] = [
    { code: 'GB', label: 'English', translate_code: 'en' },
    { code: 'DE', label: 'German', translate_code: 'de' },
    { code: 'FR', label: 'French', translate_code: 'fr' },
    { code: 'ES', label: 'Spanish', translate_code: 'es' },
    { code: 'PT', label: 'Portuguese', translate_code: 'pt' },
    { code: 'IT', label: 'Italien', translate_code: 'it' },
]

// const supportedLanguages: any = { // todo: use this to get O(1) lookback
//     German: { code: 'DE', label: 'German' },
//     French: { code: 'FR', label: 'French' },
//     English: { code: 'GB', label: 'English' },
//     Spanish: { code: 'ES', label: 'Spanish' },
//     Portuguese: { code: 'PT', label: 'Portuguese' },
//     Italien: { code: 'IT', label: 'Italien' },
// }

const LanguagePicker: FC = () => {
    const { getLang, setLang } = useChatMessage()
    useEffect(() => {
        if (!getLang) {
            setLang({ code: 'GB', label: 'English' }) // if no default lang, select English
        }
    }, [])
    return (
        <Box
            sx={{
                width: '100%',
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                // justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: '10px',
            }}
        >
            <Box>
                <img
                    style={{ borderRadius: '5px' }}
                    loading="lazy"
                    width="40"
                    height="30"
                    srcSet={`https://flagcdn.com/w40/${getLang?.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w40/${getLang?.code.toLowerCase()}.png`}
                    alt=""
                />
            </Box>
            <Autocomplete
                fullWidth
                id="country-select-demo"
                key={getLang?.code} // to reload the component on value change
                options={languages}
                autoHighlight
                value={getLang}
                getOptionLabel={(option) => option.label}
                // sx={{ flex: 1 }}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                    >
                        <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option?.code.toLowerCase()}.png`}
                            alt=""
                        />
                        {option?.label}
                    </Box>
                )}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            // label="Choose a language"
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                fullWidth: true,
                                sx: {
                                    borderRadius: '10px',
                                    //     width: 'max-content',
                                },
                                autoComplete: 'new-password',
                            }}
                        />
                    )
                }}
                onInputChange={(_, value) => {
                    if (value && setLang) {
                        const selected = languages.find(
                            // use supportedLanguages to remove this for loop
                            (lang) => lang.label === value
                        )
                        setLang(selected)
                        window.setCookie(
                            'selected_language',
                            JSON.stringify(selected)
                        )
                    }
                }}
            />
        </Box>
    )
}

export default LanguagePicker

interface CountryType {
    code: string
    label: string
    speech_code?: string
    translate_code: string
}
