import React, { FC, ReactElement } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
// import { useCookies } from 'react-cookie'

import ChatContainer from './components/container/ChatContainer'
import Initial from './components/landingPage/Initial'
import LoginPage from './views/LoginPage'
// import SignupPage from './views/SignupPage'
import MobileLayout from './components/Layouts/MobileLayout'
// import PageLayout from './components/Layouts/PageLayout'
import TermsAndConditions from './views/TermsPage'
import { ROUTES } from './functions/constants'
import PrivacyPolicy from './views/PrivacyPolicyPage'
import { COOKIES } from './functions/constants'
import { useCookies } from 'react-cookie'
// import { useSelector } from 'react-redux'
// import { getIsAuthenticated } from './store/selectors'
// import WithHeaderLayout from './components/Layouts/WithHeaderLayout'

const Router: FC = (): ReactElement => {
    const [cookies] = useCookies([COOKIES.TOKEN])

    // if (!cookies[COOKIES.TOKEN]) {
    //     return (
    //         <Routes>
    //             <Route path={ROUTES.ROOT} element={<MobileLayout />}>
    //                 <Route path="" element={<LoginPage />} />
    //             </Route>
    //         </Routes>
    //     )
    // }

    const isAuthenticated = cookies[COOKIES.TOKEN]

    return (
        <Routes>
            <Route path={ROUTES.ROOT} element={<MobileLayout />}>
                {!isAuthenticated ? (
                    <>
                        <Route path="" element={<LoginPage />} />
                    </>
                ) : (
                    <Route path="" element={<Initial />} />
                )}
                {isAuthenticated && (
                    <Route path={ROUTES.CHAT} element={<ChatContainer />} />
                )}
                <Route path={ROUTES.TERMS} element={<TermsAndConditions />} />
                <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />} />
                {/* <Route path={ROUTES.LOGIN} element={<LoginPage />} />
                <Route path={ROUTES.SIGNUP} element={<SignupPage />} /> */}
            </Route>
            <Route path="*" element={<Navigate to="" replace />} />
        </Routes>
    )
}

export default Router
