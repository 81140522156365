import { createSlice } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        token: null,
        errorCorrectStore: {}
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        setErrorCorrect: (state, action) => {
            state.errorCorrectStore[action.payload.id] = action.payload.data
        }
    },
})

export const { setToken, setErrorCorrect } = generalSlice.actions

export default generalSlice.reducer
