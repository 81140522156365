import { SECONDARY_COLOR } from './functions/constants'

export const theme = {
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: SECONDARY_COLOR,
        },
        error: {
            main: '#ff1744',
        },
        background: {
            default: '#fff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px', // Apply rounded corners to all Buttons
                    padding: '10px',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '10px', // Apply rounded corners to all Cards
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '10px',
                },
            },
        },
    },
}
