import React from 'react'
// import ReactDOM from 'react-dom/client'
import CircularProgress from '@mui/material/CircularProgress'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { COOKIES } from '../../functions/constants'
import { useCookies } from 'react-cookie'
import { useDataProvider } from '../../functions/MainFunctions'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useToast } from '../Toasts'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const SpeechRecorder = ({ setMessage, sendMessage, setLoading }) => {
    const { showToast } = useToast()

    const [cookies] = useCookies([COOKIES.AUTO_SEND])
    const dataProvider = useDataProvider()
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const detectTextInAudio = (blob) => {
        const formData = new FormData()
        formData.append('audio', blob, 'audio.mp3')
        dataProvider
            .speechToText(formData)
            .then((response) => {
                console.log('Auto send:', cookies[COOKIES.AUTO_SEND])
                if (cookies[COOKIES.AUTO_SEND]) {
                    sendMessage(response.data.data)
                } else {
                    setMessage(response.data.data)
                }
            })
            .catch((error) => {
                console.error('Error:', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const recorderControls = useAudioRecorder(
        {
            noiseSuppression: true,
            echoCancellation: true,
        },
        () => {
            showToast('Recording not allowed', 'warning')
        }
    )

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const addAudioElement = (blob) => {
        // setLoading(true)
        // const url = URL.createObjectURL(blob)
        // const audio = document.createElement('audio')
        // audio.src = url
        // audio.controls = true
        // document.body.appendChild(audio)
        detectTextInAudio(blob)
    }

    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
            <AudioRecorder
                // classes={{ AudioRecorderClass: }}
                onRecordingComplete={(blob) => {
                    console.log('recording complete', blob.size)
                    addAudioElement(blob)
                }}
                recorderControls={recorderControls}
                showVisualizer={true}
                // downloadOnSavePress={true}
                // downloadFileExtension='mp3'
            />
            {recorderControls.isRecording && (
                <IconButton
                    onClick={() => {
                        setLoading(true)
                        setTimeout(() => {
                            setLoading(false)
                        }, 100)
                    }}
                >
                    <CancelIcon />
                </IconButton>
            )}
        </div>
    )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const SpeechToText = ({ setMessage, sendMessage }) => {
    const [loading, setLoading] = React.useState(false)

    if (loading) {
        return <CircularProgress fourColor />
    }

    return (
        <SpeechRecorder
            sendMessage={sendMessage}
            setMessage={setMessage}
            setLoading={setLoading}
        />
    )
}

export default SpeechToText
