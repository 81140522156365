// eslint-disable-next-line import/named
import { AlertColor, Snackbar } from '@mui/material'
import { Alert } from '@mui/material'
import React, { createContext, useState } from 'react'

interface ToastContextProps {
    showToast: (title: string, severity: AlertColor | undefined) => void
}

interface IMessage {
    title: string | null
    severity: AlertColor | undefined
}

const ToastContext = createContext<ToastContextProps>({
    showToast: function (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _title: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _severity: AlertColor | undefined
    ): void {
        throw new Error('Function not implemented.')
    },
})

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [message, setMessage] = useState<IMessage>({
        title: null,
        severity: 'info',
    })

    const handleClose = (): void => {
        setMessage({ title: null, severity: message.severity })
    }

    const showToast = (
        title: string,
        severity: AlertColor | undefined = 'info'
    ): void => {
        if (title) {
            handleClose()
            setTimeout(() => {
                setMessage({ title, severity })
            }, 200)
        } else {
            setMessage({ title, severity })
        }
    }

    return (
        <ToastContext.Provider value={{ showToast }}>
            <Snackbar
                open={!!message.title}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleClose}
                    severity={message.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message.title}
                </Alert>
            </Snackbar>
            {children}
        </ToastContext.Provider>
    )
}

export const useToast = (): ToastContextProps => {
    const context = React.useContext(ToastContext)
    if (context === undefined) {
        throw new Error('useToast must be used within a ToastProvider')
    }
    return context
}
