import * as React from 'react'
// import Avatar from '@mui/material/Avatar'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import LoginForm from '../components/loginForm'
import { ROUTES } from '../functions/constants'
import WithHeaderLayout from '../components/Layouts/WithHeaderLayout'
// import { Image } from '@mui/icons-material'
import SignInLogo from '../images/sign-in-logo.png'

function Copyright(): React.ReactElement {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 8, mb: 4 }}
        >
            {'Copyright © '}
            <Link
                color="inherit"
                // href="https://mui.com/"
            >
                TalkMATE
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    )
}

// TODO remove, this demo shouldn't need to reset the theme.

function LoginPage(): React.ReactElement {
    return (
        <WithHeaderLayout>
            <Box
                sx={{
                    height: '75vh',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingTop: '10%',
                    paddingBottom: '5%',
                    // backgroundColor: 'red',
                    alignItems: 'center',
                }}
            >
                <Container
                    // component="main"
                    sx={{
                        // backgroundColor: 'ButtonShadow',
                        // paddingX: '5%',
                        borderRadius: '10px',
                        width: '100%',
                        // backgroundColor: 'green',
                        flex: 1,
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            // backgroundColor: 'blue',
                        }}
                    >
                        {/* <Avatar
                            sx={{
                                m: 1,
                                bgcolor: 'grey',
                                width: 66,
                                height: 66,
                            }}
                        /> */}
                        <img
                            src={SignInLogo}
                            height={60}
                            width={60}
                            style={{
                                margin: 1,
                               marginBottom: 20
                            }}
                        />
                        <Typography
                            component="h1"
                            variant="h5"
                            sx={{ fontWeight: 'bold' }}
                        >
                            Log In
                        </Typography>
                        <LoginForm />
                        {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

                        <Grid container>
                            {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
                            {/* <Grid item>
                            <Link href="/signup" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid> */}
                        </Grid>
                    </Box>
                    <div>
                        <Copyright />
                        <Typography align="center">
                            By signing in you agree to our{' '}
                            <Link href={ROUTES.TERMS}>Terms of Service</Link>
                            and{' '}
                            <Link href={ROUTES.PRIVACY}>Privacy policy</Link>
                        </Typography>
                    </div>
                </Container>
            </Box>
        </WithHeaderLayout>
    )
}

export default LoginPage
