import React from 'react'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutIcon from '@mui/icons-material/Logout'
import { setToken } from '../store/reducers/generalReducer'
import { useCookies } from 'react-cookie'

import { COOKIES, PRIMARY_COLOR } from '../functions/constants'
import { useDispatch } from 'react-redux'

export const AccountIcon = (): React.ReactElement => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const dispatch = useDispatch()
    const removeCookies = useCookies([COOKIES.TOKEN])[2]
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (): void => {
        setAnchorEl(null)
    }

    const handleLogout = (): void => {
        removeCookies(COOKIES.TOKEN)
        dispatch(setToken(null))
        handleClose()
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const parseJwt = (token: string) => {
        if (!token) {
            return
        }
        const base64Url = token.split('.')[1]
        const base64 = base64Url?.replace('-', '+').replace('_', '/')
        if(!base64) {
            return null
        }
        return JSON?.parse(window.atob(base64))
    }

    const email = parseJwt(useCookies([COOKIES.TOKEN])[0].token)?.sub || 'T'

    return (
        <>
            <div
                onClick={handleClick}
                style={
                    {
                        // padding: '5%',
                        // paddingRight: '5%',
                    }
                }
            >
                <Avatar sx={{ bgcolor: PRIMARY_COLOR, width: 50, height: 50 }}>
                    {email[0].toUpperCase()}
                </Avatar>
            </div>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
        </>
    )
}
