import * as React from 'react'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import CardActionArea from '@mui/material/CardActionArea'
import { ClickableCard } from '../Basic/ClickableCard'
import {
    SECONDARY_COLOR,
    SELECTED_BACKGROUND_COLOR,
} from '../../functions/constants'
// import { SECONDARY_COLOR } from '../../functions/constants'
// import styled from '@emotion/styled'

// const HoverCard = styled(Card)(() => ({
//     margin: '10px',
//     transition: 'transform 0.15s ease-in-out',
//     '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
// }))

export default function SituationCard({
    selected,
    title,
    description,
    image,
    onClick,
}): React.ReactElement {
    const backgroundColor = selected ? SELECTED_BACKGROUND_COLOR : 'white'

    return (
        <ClickableCard
            onClick={onClick}
            sx={{
                border: selected ? `1px solid ${SECONDARY_COLOR}` : 'white',
                backgroundColor: backgroundColor,
            }}
        >
            <CardActionArea
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <CardMedia
                    component="img"
                    height="140"
                    image={image}
                    alt="green iguana"
                    sx={{ width: 1 / 3 }}
                />
                <CardContent
                    sx={{
                        backgroundColor: backgroundColor,
                        flex: 1,
                        justifyContent: 'stretch',
                        alignItems: 'stretch',
                    }}
                >
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </ClickableCard>
    )
}
