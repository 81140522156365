import { createSlice } from '@reduxjs/toolkit'

export const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        getLang: null,
        getUID: null,
        getThreadID: '',
        getChats: [],
        getSituation: null,
    },
    reducers: {
        _setLang: (state, action) => {
            state.getLang = action.payload
        },
        _setUID: (state, action) => {
            state.getUID = action.payload
        },
        _setThreadID: (state, action) => {
            state.getThreadID = action.payload
        },

        _setChats: (state, action) => {
            state.getChats = action.payload
        },

        _setSituation: (state, action) => {
            state.getSituation = action.payload
        },
    },
})

export const { _setLang, _setUID, _setThreadID, _setChats, _setSituation } =
    chatSlice.actions

export default chatSlice.reducer
