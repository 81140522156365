import styled from '@emotion/styled'
import Card from '@mui/material/Card'

export const ClickableCard = styled(Card)(() => ({
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
    margin: '10px',
    borderRadius: '10px',
    width: '100%',
    boxShadow: '0px 2px 8px #00000040'
}))
