const prod = {
    colors: {
        PRIMARY_COLOR: '#FF6B6B',
    },
    url: {
        GET_LANGS: 'https://api.talkmate.lk/languages_list',
        GET_RESPONSE: 'https://api.talkmate.lk/generate_response/',
        BACKEND_URL: 'https://api.talkmate.lk',
    },
}

const DEV_URL = 'http://localhost:8000'

const dev = {
    colors: {
        PRIMARY_COLOR: '#006B6B',
    },
    url: {
        GET_LANGS: DEV_URL + '/languages_list',
        GET_RESPONSE: DEV_URL + '/generate_response/',
        BACKEND_URL: DEV_URL,
    },
}
export const config = process.env.NODE_ENV === 'development' ? dev : prod //currently docker running in dev so keep it as prod

export const RESET_CHAT_URL = config.url.BACKEND_URL + '/reset_chat'
export const TEXT_TO_SPEECH_URL = config.url.BACKEND_URL + '/text_to_voice/'

export const BACKEND_URL = config.url.BACKEND_URL
export const PRIMARY_COLOR = config.colors.PRIMARY_COLOR
export const SECONDARY_COLOR = '#fed136'
export const TERTIARY_COLOR = 'darkseagreen'
export const DARK_BACKGROUND = '#0f1626'
export const LIGHT_BACKGROUND = 'floralwhite'

export const LOGIN_URL = BACKEND_URL + '/token'

export const ROUTES = {
    ROOT: '/',
    CHAT: '/chat',
    TERMS: '/terms',
    LOGIN: '/login',
    SIGNUP: '/signup',
    PRIVACY: '/privacy',
}

export const CONTACT_EMAIL_ADDRESS = 'fmdksaf@gmail.com'

export const COOKIES = {
    TOKEN: 'token',
    IS_MUTED: 'isMuted',
    AUTO_SEND: 'autoSend',
}

export const WINDOW_BACKGROUND = '#242A37'
export const INITIAL_HEADER_BACKGROUND = DARK_BACKGROUND
export const TYPING_CONTAINER_BACKGROUND = 'white'

export const ICON_COLOR = 'yellow'

export const SELECTED_BACKGROUND_COLOR = '#eee'
