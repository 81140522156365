import React, { FC, ReactNode } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
    INITIAL_HEADER_BACKGROUND,
    LIGHT_BACKGROUND,
} from '../../functions/constants'
// import Paper from '@mui/material/Paper'
import styled from '@emotion/styled'
// import { keyframes } from '@emotion/react'
// import { Outlet } from 'react-router-dom'

const GlassContainer = styled(Box)(({}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '70vh',
    width: '100%',
    backdropFilter: 'blur(10px)',
    borderRadius: '32px 32px 0 0',
    // padding: '12px',
    // boxShadow: theme.shadows[3],
    // width: '100%', // Adjusted width for responsiveness
    // position: 'relative',
    // overflow: 'hidden',
    boxSizing: 'border-box',
    background: LIGHT_BACKGROUND,
    // opacity: 0.9,
    // '@media (min-width: 768px)': {
    //     width: '40%', // Adjust as needed for larger screens
    // },
}))

// const fadeInUp = keyframes`
//     from {
//         opacity: 0;
//         transform: translateY(20px);
//     }
//     to {
//         opacity: 1;
//         transform: translateY(0);
//     }
// `

type WithHeaderLayoutProps = {
    children: ReactNode
    topRight?: ReactNode
}

const WithHeaderLayout: FC<WithHeaderLayoutProps> = ({
    children,
    topRight,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Center align the content
                justifyContent: 'center', // Center align vertically
                // height: '100%',
                maxHeight: 'var(--app-height)',
                minHeight: 'var(--app-height)',
                overflow: 'hidden',
                // background: '#0f1626',
                backgroundColor: INITIAL_HEADER_BACKGROUND,
                backgroundSize: 'cover',
                fontWeight: 600,
                width: '100%',
                flex: 1,
                WebkitJustifyContent: 'space-between',
                // height: '30vh'
            }}
        >
            <Box
                sx={{
                    // height: '200px',
                    maxHeight: '20vh',
                    minHeight: '20vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Center align the content
                    justifyContent: 'center', // Center align vertically
                    // background: '#0f1626',
                    backgroundColor: INITIAL_HEADER_BACKGROUND,
                    backgroundSize: 'cover',
                    fontWeight: 600,
                    width: '100%',
                    WebkitJustifyContent: 'space-between',
                    zIndex: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'end',
                        justifyContent: 'flex-end',
                        width: '100%',
                        paddingY: '2%',
                        height: '5vh',
                        paddingRight: '5%',
                        // backgroundColor: 'red',
                    }}
                >
                    {topRight}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '15vh',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                            // paddingBottom: '0px',
                            paddingTop: '0px',
                            visibility: 'visible',
                            animationDelay: '0.3s',
                            animationName: 'bounceIn',
                        }}
                        style={{ color: 'darkseagreen', fontWeight: 100 }}
                    >
                        <span style={{ color: 'floralwhite' }}>Talk </span>MATE
                    </Typography>
                    {/* <Typography
                        variant="h3"
                        component="div"
                        sx={{
                            fontSize: 'large',
                            fontWeight: 100,
                            color: '#fed136',
                            animation: `${fadeInUp} 1s ease-in-out`,
                            fontFamily: 'monospace',
                            textAlign: 'center', // Center align the text
                            paddingBottom: '12px',
                        }}
                    >
                        We unveil unlimited business possibilities
                    </Typography> */}
                </Box>
            </Box>
            <GlassContainer
                // elevation={0}
                sx={{
                    // padding: '3%',
                    maxHeight: '80vh',
                    flexDirection: 'column',
                    overflowX: 'hidden',
                }}
            >
                {children}
            </GlassContainer>
        </Box>
    )
}

export default WithHeaderLayout
